import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import breakpoints from "../../styles/breakpoints";
import {
  fontR14m,
  fontR14r,
  fontFG20m,
  fontR16r,
  fontR16m,
} from "../../styles/fonts";
import moment from "moment";
import routes from "../../routes";
import SmartLink from "../shared/SmartLink";
import TagButton from "../shared/TagButton";
import colors from "../../styles/colors";
import EmptyList from "../shared/EmptyList";

const Author = styled.div``;

const Date = styled.div``;

const AuthorDate = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.blueGrey};
  ${Author} {
    ${fontR16m};
  }
  ${Date} {
    ${fontR16r};
  }
  @media (min-width: ${breakpoints.lg}) {
    ${Author} {
      ${fontR14m};
    }
    ${Date} {
      ${fontR14r};
    }
  }
`;

const AvatarImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

const Avatar = styled.div`
  margin-right: 12px;
  width: 50px;
  height: 50px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
`;

const AuthorSection = styled.div`
  margin-top: 20px;
  display: flex;
`;

const Image = styled.div`
  img {
    width: 100%;
  }
  @media (min-width: ${breakpoints.lg}) {
    height: 0;
    overflow: hidden;
    padding-top: 47.8%;
    position: relative;
    a {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const Description = styled.div`
  overflow: hidden;
`;

const Title = styled.div`
  ${fontFG20m};
  a {
    color: inherit;
    text-decoration: none;
  }
`;

const Post = styled.div`
  margin-bottom: 60px;

  ${Image} {
    margin-bottom: 21px;
  }

  ${Date} {
    margin-bottom: 18px;
  }

  ${Title} {
    margin-top: 16px;
    margin-bottom: 18px;
  }

  ${Description} {
    ${fontR16r};
  }

  @media (min-width: ${breakpoints.lg}) {
    ${Image} {
      margin-bottom: 30px;
    }

    ${Date} {
      margin-bottom: 21px;
    }

    ${Title} {
      margin-top: 8px;
      margin-bottom: 21px;
    }
    ${Description} {
      ${fontR14r};
    }
  }
`;

const Wrapper = styled.div``;

const Container = styled.div`
  margin-top: 50px;

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 0;

    ${Wrapper} {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 32px;
    }
  }
`;

// allPosts come from Gatsby GraphQL or during build time
// jsonPosts are from Prismic REST API. It's a different format from the GraphQL
// If jsonPosts is available, it will be used. Otherwise, fallback to allPosts
// TODO: Investigate dynamic filter using GraphQL or React Hydrate
const Posts = ({ allPosts, jsonPosts, isEmpty }) => {
  if (isEmpty) return <EmptyList />;

  const posts = jsonPosts ? jsonPosts : allPosts.edges;

  return (
    <Container>
      <Wrapper>
        <>
          {posts.map((post) => {
            const { uid, tags, data } = jsonPosts ? post : post.node;
            const { banner_image, title, description, avatar, author, date } =
              data;
            const bannerImageData = getImage(banner_image);
            const avatarImageData = getImage(avatar);
            return (
              <Post key={uid}>
                {bannerImageData && (
                  <Image>
                    <SmartLink to={routes.blog + "/" + uid}>
                      <GatsbyImage
                        image={bannerImageData}
                        alt={banner_image.alt || ""}
                      />
                    </SmartLink>
                  </Image>
                )}
                <TagWrapper>
                  {tags.slice(0, 3).map((tag, index) => (
                    <TagButton key={index} type="primary" text={tag} />
                  ))}
                </TagWrapper>
                <Title>
                  <SmartLink to={routes.blog + "/" + uid}>{title}</SmartLink>
                </Title>
                <Description>{description}</Description>
                <AuthorSection>
                  {avatarImageData && (
                    <Avatar>
                      <AvatarImage image={avatarImageData} alt={avatar.alt} />
                    </Avatar>
                  )}
                  <AuthorDate>
                    <Author>{author}</Author>
                    <Date>{date && moment(date).format("D MMM YYYY")}</Date>
                  </AuthorDate>
                </AuthorSection>
              </Post>
            );
          })}
        </>
      </Wrapper>
    </Container>
  );
};

export default Posts;
