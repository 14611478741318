import styled from "styled-components";
import { fontFG52m, fontFG20l } from "../../styles/fonts";
import Grid from "../../styles/Grid";
import breakpoints from "../../styles/breakpoints";
import React from "react";

const Title = styled.h1`
  ${fontFG52m};
  text-align: center;
  margin-bottom: 30px;
`;

const Brief = styled.div`
  ${fontFG20l};
  text-align: center;
`;

const Container = styled(Grid)`
  ${Title} {
    grid-column: 2 / span 4;
  }
  ${Brief} {
    grid-column: 2 / span 4;
  }

  @media (min-width: ${breakpoints.lg}) {
    ${Title} {
      grid-column: 6 / span 4;
    }
    ${Brief} {
      grid-column: 4 / span 8;
      margin-bottom: 60px;
    }
  }
`;

const Header = ({ title, brief }) => (
  <Container>
    <Title>{title}</Title>
    <Brief>{brief}</Brief>
  </Container>
);

export default Header;
