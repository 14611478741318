import React from "react";
import styled from "styled-components";

const EmptyMessage = styled.div`
  text-align: center;
  max-width: 400px;
  margin: 60px auto;

  img {
    margin-bottom: 30px;
  }

  p {
    margin-bottom: 1.5em;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const EmptyList = () => (
  <EmptyMessage>
    <img
      alt="Monkey typewriter gif"
      src="https://media.giphy.com/media/1229mlttgo8aR2/giphy.gif"
    />
    <p>
      Our monkeys are busy hitting random keys on typewriters. Please come back
      when they have produced a post or the complete works of Shakespeare.
    </p>
    <p>
      Find out more about the{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://en.wikipedia.org/wiki/Infinite_monkey_theorem"
      >
        Infinite monkey theorem
      </a>
      .
    </p>
  </EmptyMessage>
);

export default EmptyList;
