import React from "react";
import { Icon } from "antd";

const LoadingSpinner = ({ text = "" }) => {
  return (
    <div style={{ position: "relative" }}>
      <Icon
        style={{
          position: "absolute",
          left: "50%",
          fontSize: "5rem"
        }}
        type="loading"
      />
      {text.length > 0 && <h3 style={{ textAlign: "center" }}>{text}</h3>}
    </div>
  );
};

export default LoadingSpinner;
