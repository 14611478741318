import React from "react";
import styled from "styled-components";
import { fontFG20m } from "../../styles/fonts";
import arrowRight from "../../assets/images/arrow-right-medium.svg";
import { FormattedMessage } from "react-intl";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";
import SmartLink from "../shared/SmartLink";

const RightArrow = styled.i`
  height: 16px;
  width: 16px;
  display: inline-block;
  background: url(${arrowRight}) no-repeat;
  transform: scale(1.25, 1.25);
`;

const LeftArrow = styled(RightArrow)`
  transform: scale(1.25, 1.25) rotate(180deg);
`;

const LinkText = styled.div`
  ${fontFG20m}
`;

const Navigator = styled(SmartLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${colors.black};
`;

const Newer = styled.div`
  ${LinkText} {
    margin-left: 20px;
  }
`;
const Older = styled.div`
  ${LinkText} {
    margin-right: 20px;
  }
`;

const Navigators = styled.div`
  display: flex;
  justify-content: space-between;

  > * {
    margin-bottom: 30px;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 92px;
  }
`;

const PageNavigation = ({
  totalPages,
  currentPage,
  route,
  onNavigate = null,
}) => {
  const olderPostsPage = currentPage + 1 > totalPages ? null : currentPage + 1;
  const newerPostsPage = currentPage > 1 ? currentPage - 1 : null;

  return totalPages > 1 ? (
    <Navigators>
      <>
        {newerPostsPage && (
          <Newer>
            <Navigator
              onClick={onNavigate ? () => onNavigate(newerPostsPage) : null}
              to={
                onNavigate
                  ? null
                  : route + "/" + (newerPostsPage === 1 ? "" : newerPostsPage)
              }
              display={currentPage === 1 ? "none" : "flex"}
            >
              <LeftArrow />
              <LinkText>
                <FormattedMessage id="newer_posts" />
              </LinkText>
            </Navigator>
          </Newer>
        )}
        {olderPostsPage && (
          <Older>
            <Navigator
              onClick={onNavigate ? () => onNavigate(olderPostsPage) : null}
              to={onNavigate ? null : route + "/" + olderPostsPage}
              display={currentPage === totalPages ? "none" : "flex"}
            >
              <LinkText>
                <FormattedMessage id="older_posts" />
              </LinkText>
              <RightArrow />
            </Navigator>
          </Older>
        )}
      </>
    </Navigators>
  ) : null;
};

export default PageNavigation;
