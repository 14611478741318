// Prismic REST API for fetching data at runtime
import axios from "axios";

// Prismic CMS config.
const PRISMIC_ROOT = "https://helpster.prismic.io/api/v2";
const PRISMIC_CDN_ROOT = "https://helpster.cdn.prismic.io/api/v2";

const fetchRestApiRef = () => {
  return axios.get(PRISMIC_ROOT);
};

const fetchBlogPostsByTag = (masterRef, tagName, pageSize, page, lang) => {
  // Encode characters in tag name that are reserved for URLs
  const encodedTagName = encodeURIComponent(tagName);
  return axios.get(
    PRISMIC_CDN_ROOT +
      `/documents/search?ref=${masterRef}&q=%5B%5Bat(document.type%2C+"blog_post")%5D%5D&q=%5B%5Bat(document.tags%2C+%5B"${encodedTagName}"%5D)%5D%5D&pageSize=${pageSize}&page=${page}&lang=${lang}`
  );
};

export const prismicService = {
  fetchRestApiRef,
  fetchBlogPostsByTag
};
