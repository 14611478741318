import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import styled from "styled-components";
import Header from "../components/shared/Header";
import Posts from "../components/blogList/Posts";
import PageNavigation from "../components/shared/PageNavigation";
import Seo from "../components/shared/seo";
import Grid from "../styles/Grid";
import breakpoints from "../styles/breakpoints";
import colors from "../styles/colors";
import BlogSubscriptionBanner from "../components/shared/BlogSubscriptionBanner";
import TagButton from "../components/shared/TagButton";
import { prismicService } from "../services/prismic";
import LoadingSpinner from "../components/shared/LoadingSpinner";
import routes from "../routes";
import { FormattedMessage } from "react-intl";

const TagTitle = styled.h3`
  text-transform: uppercase;
`;
const TagWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
`;
const TagContainer = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.greyBlue};
  border-radius: 4px;
`;
const TagColumn = styled.div``;

const PostContainer = styled.div``;

const Container = styled(Grid)`
  padding-top: 40px;

  ${TagColumn} {
    grid-column: 2 / span 4;
    order: 1;
  }
  ${PostContainer} {
    grid-column: 2 / span 4;
    display: grid;
  }
  @media (min-width: ${breakpoints.lg}) {
    ${PostContainer} {
      grid-column: 6 / span 7;
      display: grid;
      order: 1;
    }
  }
`;

class BlogList extends React.Component {
  state = {
    masterRef: "",
    selectedTag: undefined,
    filteredPosts: undefined,
    filteredCurrentPage: undefined,
    filteredTotalPages: undefined,
  };

  componentDidMount() {
    // window.analytics.page("Blog");

    // The API ref is required to specify the content version/branch you'd like to query.
    // For public content, you will use the master ref. The master ref changes every time our publish changes.
    // Whenever we do a search query, we need to pass along the most recent master ref.
    // https://prismic.io/docs/rest-api/basics/introduction-to-the-content-query-api
    prismicService.fetchRestApiRef().then((response) => {
      const masterRef = response.data.refs.filter((ref) => ref.isMasterRef);
      this.setState({ masterRef: masterRef[0].ref });
    });
  }

  refreshBlogPostList = (page = 1) => {
    const { masterRef, selectedTag } = this.state;
    const {
      pageContext: { postPerPage, localeKey },
    } = this.props;
    this.setState({ isLoading: true });
    prismicService
      .fetchBlogPostsByTag(masterRef, selectedTag, postPerPage, page, localeKey)
      .then((response) => {
        this.setState({
          filteredPosts: response.data.results,
          filteredCurrentPage: response.data.page,
          filteredTotalPages: response.data.total_pages,
          isLoading: false,
        });
      });
  };

  handleTagClick = (tag) => {
    const { selectedTag } = this.state;
    if (selectedTag === tag) {
      this.setState({
        selectedTag: undefined,
        filteredPosts: undefined,
      });
    } else {
      this.setState({ selectedTag: tag }, () => this.refreshBlogPostList());
    }
  };

  render() {
    const {
      location,
      pageContext: { locale, totalPages, currentPage },
      data,
    } = this.props;
    const {
      selectedTag,
      filteredPosts,
      filteredCurrentPage,
      filteredTotalPages,
      isLoading,
    } = this.state;
    const blogListData = data.prismicBlogListPage.data;
    const metaImage =
      data.allPrismicBlogPost.edges.length > 0 &&
      data.allPrismicBlogPost.edges[0].node.data.banner_image.url;
    const noResult =
      !data.allPrismicBlogPost || (filteredPosts && filteredPosts.length === 0);

    return (
      <>
        <Layout location={location} locale={locale}>
          <Seo
            title={`${blogListData.title} | Workmate`}
            description={blogListData.brief}
            image={metaImage}
          />
          <Container>
            <Header title={blogListData.title} brief={blogListData.brief} />
            <TagColumn>
              <TagContainer>
                <TagTitle>
                  <FormattedMessage id={"tags"} />
                </TagTitle>
                <TagWrapper>
                  {data.allPrismicTag.edges.map(({ node }, index) => (
                    <TagButton
                      key={index}
                      text={node.data.tag_name}
                      type="secondary"
                      style={
                        selectedTag === node.data.tag_name
                          ? { backgroundColor: colors.green, cursor: "pointer" }
                          : { cursor: "pointer" }
                      }
                      onClick={() => this.handleTagClick(node.data.tag_name)}
                    />
                  ))}
                </TagWrapper>
              </TagContainer>
            </TagColumn>
            <PostContainer>
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <Posts
                    allPosts={data.allPrismicBlogPost}
                    jsonPosts={filteredPosts}
                    isEmpty={noResult}
                  />
                  <PageNavigation
                    currentPage={
                      filteredPosts ? filteredCurrentPage : currentPage
                    }
                    totalPages={filteredPosts ? filteredTotalPages : totalPages}
                    onNavigate={filteredPosts ? this.refreshBlogPostList : null}
                    route={routes.blog}
                  />
                </>
              )}
            </PostContainer>
          </Container>
          <BlogSubscriptionBanner
            title={blogListData.cta_banner_title}
            description={blogListData.cta_banner_description}
            hubspotFormId={blogListData.hubspot_form_id}
          />
        </Layout>
      </>
    );
  }
}

export default BlogList;

export const blogListQuery = graphql`
  query blogListQuery($localeKey: String, $skip: Int!, $limit: Int!) {
    allPrismicBlogPost(
      limit: $limit
      skip: $skip
      filter: { lang: { eq: $localeKey } }
      sort: { fields: [data___date], order: DESC }
    ) {
      edges {
        node {
          uid
          lang
          tags
          data {
            banner_image {
              alt
              url
              gatsbyImageData
            }
            title
            author
            description
            date
            avatar {
              alt
              url
              gatsbyImageData
            }
          }
        }
      }
    }
    prismicBlogListPage(lang: { eq: $localeKey }) {
      data {
        title
        brief
        cta_banner_title
        cta_banner_description
        hubspot_form_id
      }
    }
    allPrismicTag(filter: { lang: { eq: $localeKey } }) {
      edges {
        node {
          data {
            tag_name
          }
        }
      }
    }
  }
`;
